<template>
  <div class="visible-box" ref="box">
    <div :class="['ani-container ftco-animate', direction , {'ftco-animated': animated}]">
      <slot></slot>
    </div>
  </div>
</template>

<script>

export default {
  name: 'VisibleBox',
  inject: ['reactiveScrollTop'],
  props: {
    direction: {
      type: String,
      default: 'up' //up,bottom
    }
  },
  computed: {
    scrollTop () {
      return this.reactiveScrollTop()
    }
  },
  watch:{
    scrollTop(){
      this.handlerScroll()
    }
  },
  data(){
    return {
      animated: false
    }
  },
  mounted(){
    this.handlerScroll()
  },
  methods:{
    handlerScroll(){
      const boxRef = this.$refs.box
      if(boxRef && ((boxRef.offsetTop - window.innerHeight + 200) <= this.scrollTop || boxRef.offsetTop < window.innerHeight) && !this.animated){
        this.animated = true
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@keyframes fadeInUp {
  from {
    opacity: 0;
    visibility: hidden;
    transform: translate3d(0, 50px, 0);
  }

  to {
    visibility: visible;
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes fadeInBottom {
  from {
    opacity: 0;
    visibility: hidden;
    transform: translate3d(0, -50px, 0);
  }

  to {
    visibility: visible;
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

.ftco-animate {
  opacity: 0;
  visibility: hidden;
  // position: relative;
  // z-index: 2;
}

.ftco-animated {
  // animation-name: fadeInUp;
  animation-duration: .6s;
  animation-fill-mode: both;
  &.up{
    animation-name: fadeInUp;
  }
  &.bottom{
    animation-name: fadeInBottom;
  }
}

</style>
