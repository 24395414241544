<template>
  <!-- <div class="about-company">
    <div class="img-wrap">
      <img :src="img" alt="">
    </div>
    <div class="text" v-html="baseInfo.company_desc"></div>
  </div> -->
  <div class="about-company main" style="padding: 30px 0" v-if="baseInfo.company_desc">
    <VisibleBox>
      <Title title="about us"/>
      <div class="about-content ">
        <div class="img-wrap" style="float: left; clear: both;" align="center">
          <img :src="img" alt="">
        </div>
        <div class="text text-justifiy" v-html="baseInfo.company_desc"></div>
      </div>
    </VisibleBox>
  </div>
</template>

<script>
import Title from '../components/Title.vue'
import VisibleBox from '../components/VisibleBox.vue'

export default {
  name: 'AboutCompany',
  inject: ['getBaseInfo'],
  components: {
    Title,
    VisibleBox
  },
  props: {

  },
  computed:{
    baseInfo(){
      return this.getBaseInfo()
    }
  },
  data () {
    return {
      img: require(`../assets/about/intro.jpg`)
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.about-company{
  // font-size: 16px;
  // line-height: 26px;
  // color: rgba(0,0,0,.7);
  // text-indent: 2em;
  // line-height: 26px;
  .about-content{
    // display: flex;
    .img-wrap{
      width: 35%;
      margin-right: 30px;
      overflow: hidden;
      margin-top: 1em;
      img{
        width: 100%;
        border-radius: 10px;
      }
    }
    .text{
      flex: 1;
      // color: rgba(0,0,0,.7);
      // text-indent: 2em;
      ::v-deep p{
        font-size: 16px;
        margin: 1em 0;
      }
    }
  }
}
</style>