<template>
  <div :class="['header', {fixed: isFixed, sleep: isFixedSleep, active: isFixedActive}]">
    <div class="header-inner main">
      <div class="logo">
        <!-- <h1 v-show="isFixedActive">{{baseInfo.company_name}}</h1> -->
        <img src="../assets/logo.png" alt="">
        <!-- <img src="data:image/webp;base64,UklGRioDAABXRUJQVlA4TB4DAAAvgoAFEI9AkG3TIdwf+AoCgRQ2wgKBFDbCBNk2wx71jvAE+2OnCoJiI0mOJKmzskhXF5vTX9vriMh+IkBE/yG4bSNJmj5mgK3Zke0k1W+4/mfHXv8m49PMzFoHiGLi6F2xdYVzS5zZTB4eyKk3Qwg5jzTGzexXkN7TvK6VzQpCOTHOanZHH9pf5GzmW+G8J3hxI7g4huXICQvAmgn+x2y+P3CyNWFUFmrzVlLM3ki3mdVAQiIwYRQ5mDQG8KaAwjojkM5pFGwC09kxP/IGDB4knU24XKgyBGbwh2GapDlQkHmNyR/2RdTKAouEDfnSrw9G9alwdiJkcdxFkux0s5NXUmn2oYMW3iZ6cyI8Rm59MGcfJbS90oKCqEMCt34LA01p21skFxQrOnJY4xTJKqM+HNNfU4OF6aha2ekU0RRJF3UQtPChX6x4ghtBsCrHS8edB3SejGNn8PHQuyycZvHpkG/w0iuXWf9o1JFxP+XDwlGBZNA7hEnAbsYtHwQdvNAjB5Ai9OjKvFbuij0tKI0gpq7qtSEDd83O9n4BmoiDHkG+DzUE8OFkDYz6AZG2+m6AFMRap+i5/dfDJyiPaibl9oCpCEnoXOXR+8Gopmi3QZiwUK4E6vQiPuCpnphdny1gStyyC8io8QLkH0kGS/RoDBZupywXIECzfRiYaXPQNTAXdEBg+60u3SSBiV2qEMdUK4nogXfdbJK2c9B3oOwEGhv8ijiyKghB40NPS7GSyvLe/qHZLlYEpi+98iQByBMMRuuHbUEfHbyIF9bBG1mbgTxBNhjzEUVG+36s0fXbMtmZu+OsEVTZzPVKdLhE8hKr3PJpI0lh+Vzhntn127Cf3Mfg/1VdPmLhcgE9g91nWZc27nDP34kv3YEnfZ4cxcFW4mCLR1AVC+LU3+xia+yQb9BpM1n7uAWprr2qiHgXKKCJ4ypeeJzwEvWmSw8abw9F6PKDT4tlRk8dW24lPtkoMO/QBvLHLK3g5sBXuHxqFWFmMXPrgQdW3DNMHKXvD4/M9GESxOpu+lgPAbRgLBWS4yBhSx1gw+z5Bzoh3As=" alt=""> -->
      </div>
      <div class="tabs">
        <template v-for="(tab, index) in tabs" >
          <router-link :key="tab.name" :to="{ name: tab.name }">
            <div :class="['tab', {active: index === activeIndex}]">
              <div class="name">{{tab.title}}</div>
            </div>
          </router-link>
        </template>
      </div>
      <div class="call">
        <span class="icon"><i class="custom-icon2-phone"></i></span>
        <span>{{baseInfo.phone}}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Header',
  // inject: ['getBaseInfo'],
  props: {
    msg: String,
    baseInfo: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {
      tabs: [
        {title: 'Home', name: 'home'},
        {title: 'About', name: 'about'},
        {title: 'Products', name: 'products'},
        // {title: 'Services', name: 'services'},
        {title: 'Contact', name: 'contact'}
      ],
      isFixed: false,
      isFixedActive: false,
      isFixedSleep: false,
      activeIndex: 0,
      // baseInfo: this.getBaseInfo()
    }
  },
  watch: {
    $route: {
      handler: function(){
          const index = Math.max(this.tabs.findIndex(item => item.name === this.$route.name), 0)
          this.activeIndex = index
          
      },
      immediate: true
    }
  },
  mounted() {
    this.$nextTick(() => {
        window.addEventListener('scroll', this.onScroll);
    })
  },
  beforeDestroy() { 
    window.removeEventListener('scroll', this.onScroll); 
  },
  methods: {
    onScroll(e){
      const top = e.target.scrollingElement.scrollTop
      if(top >= 80 && this.isFixed == false){
        this.isFixed = true
        setTimeout(() => {
          this.isFixedSleep = true
          this.isFixedActive = true
        }, 200)
      }
      
      if(top < 80 && this.isFixedActive == true){
        this.isFixedActive = false
        setTimeout(() => {
          this.isFixed = false
          this.isFixedSleep = false
        }, 200)
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import '../styles/variables.scss';
  .header{
    padding: 10px 0;
    width: 100%;
    z-index: 9;
    color: rgba(0, 0, 0, 0.8);
    &.fixed{
      position: fixed;
      top:0;
      left:0;
      // padding: 16px 0;
      background-color: #fff;
      // color: #000;
      z-index: 99;
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
      transform: translateY(-100%);
      &.active{
        transform: translateY(0);
      }
      &.sleep{
        transition: transform 0.3s ease-out;
      }
      .tabs{
        .tab{
          font-size: 18px;
        }
      }
    }
    .header-inner{
      width: 80%;
      display: flex;
      margin: 0 auto;
      align-items: center;
    }
    .logo{
      font-size: 22px;
      color: $primary-color;
      margin-right: 80px;
      img{
        position: relative;
        width: 110px;
      }
    }
    .tabs{
      flex: 1;
      display: flex;
      align-items: center;
      // justify-content: flex-end;
      .tab{
        display: inline-block;
        padding: 0 24px;
        // letter-spacing: 2px;
        position: relative;
        font-weight: 400;
        font-size: 18px;
        cursor: pointer;
        &.active, &:hover{
          color: $primary-color;
          font-weight: 500;
          // &::after{
          //   content: '';
          //   position: absolute;
          //   bottom: -12px;
          //   left: 50%;
          //   transform: translateX(-50%);
          //   width: 5px;
          //   height: 5px;
          //   background-color: $text-color;
          //   border-radius: 100%;
          // }
        }
      }
    }
    .call{
      background-color: $primary-color;
      color: #fff;
      display: flex;
      align-items: center;
      padding: 8px 28px 10px 24px;
      border-radius: 40px;
      font-size: 15px;
      .icon{
        display: inline-block;
        width: 18px;
        margin-right: 10px;
        position: relative;
        top: 3px;
        i{
          font-size: 18px;
          position: relative;
          top:-2px;
        }
      }
    }
  }
</style>