<template>
  <div class="pagination">
      <div :class="['btn-page btn-prev', {disabled: currentIndex <= 0}]" @click="currentIndex > 0 && onChange(currentIndex-1)"></div>
      <div :class="['btn-page', {active: currentIndex === index}]" 
           v-for="(page, index) in pages" 
           :key="index"
           @click="onChange(index)" >{{index+1}}</div>
      <div :class="['btn-page btn-next', {disabled: currentIndex >= pages.length-1}]" @click="currentIndex < pages.length-1 && onChange(currentIndex+1)"></div>
  </div>
</template>

<script>
export default {
  name: 'Pagination',
  props: {
    total: {
      type: Number,
      default: 1
    },
    size: {
      type: Number,
      default: 10
    },
    page: {
      type: Number,
      default: 1
    }
  },
  data () {
    return {
      currentIndex: 0
    }
  },
  computed: {
    pages(){
      return new Array(Math.ceil(this.total/this.size)).fill('')
    }
  },
  watch:{
    page(nval){
      if(nval === this.currentIndex){
        return 
      }
      this.currentIndex = nval-1 || 0
    }
  },
  methods:{
    onChange(index){
      if(this.currentIndex == index){
        return
      }
      this.currentIndex = index
      this.$emit('change', index+1)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import '../styles/variables.scss';

.pagination{
  display: flex;
  justify-content: center;
  padding: 20px 0;
  .btn-page{
    width: 34px;
    height: 34px;
    line-height: 32px;
    text-align: center;
    background-color: #fff;
    font-size: 16px;
    color: $primary-color; 
    background-color: #fff;
    // border: 1px solid $primary-color; 
    border-radius: 50%;
    margin-right: 8px;
    cursor: pointer;
    &.active{
      color: #fff; 
      background-color: $primary-color; 
    }
    &.disabled{
      opacity: 0.4;
      cursor: not-allowed;
    }
    &.btn-prev{
      &::before{
        content: '';
        display: inline-block;
        width: 6px;
        height: 6px;
        border-width: 1px;
        border-style: solid;
        border-color: $primary-color transparent transparent $primary-color;
        transform: rotate(-45deg);
        position: relative;
        top: -2px;
        left: 2px;

      }
    }
    &.btn-next{
      &::before{
        content: '';
        display: inline-block;
        width: 6px;
        height: 6px;
        border-width: 1px;
        border-style: solid;
        border-color: $primary-color  $primary-color transparent transparent;
        transform: rotate(45deg);
        position: relative;
        top: -2px;
        right: 2px;

      }
    }
  }
}
</style>