<template>
  <div id="app">
    <Header :baseInfo="baseInfo"/>
    <router-view></router-view>
    <Footer :baseInfo="baseInfo"/>
  </div>
</template>

<script>
import Header from './components/Header.vue'
// import Home from './pages/Home.vue'
import Footer from './components/Footer.vue'
import { getBaseData } from './api/api'

export default {
  name: 'App',
  provide() {
    return {
      reactiveScrollTop: () => this.top,
      getBaseInfo: () => this.baseInfo
    }
  },
  data(){
    return {
      top: 0,
      baseInfo: {}
    }
  },
  components: {
    // Home,
    Header,
    Footer
  },
  async created(){
    const res = await getBaseData()
    this.baseInfo = res.data || {}
  },
  mounted() {
    this.$nextTick(() => {
       window.addEventListener('scroll', this.onScroll);
    })
  },
  beforeDestroy() { 
    window.removeEventListener('scroll', this.onScroll); 
  },
  methods: {
    onScroll(e){
      this.top = e.target.scrollingElement.scrollTop
    }
  }
}
</script>

<style lang="scss" >
@import './styles/variables.scss';

#app {
  color: $text-color;
  font-size: 14px;
  position: relative;
}
</style>
