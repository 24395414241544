<template>
  <Slider class="partners" v-if="type === 'slider'" :list="list" :size="5">
    <template slot-scope="{item}">
      <div class="partnet">
        <div class="img-wrap">
          <div class="img" :style="{'background-image': `url(${item.img})`}"></div>
        </div>
        <!-- <div class="text">
          {{item.name}}
        </div> -->
      </div>
    </template>
  </Slider>
  <div v-else class="partners list-container">
    <div class="partnet-wrap" v-for="(item, index) in list" :key="index">
      <div class="partnet">
        <div class="img-wrap">
          <div class="img" :style="{'background-image': `url(${item.img})`}"></div>
        </div>
        <!-- <div class="text">
          {{item.name}}
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import Slider from './Slider.vue'

export default {
  name: 'Partners',
  components: {
    Slider
  },
  props: {
    type: {
      type: String,
      default: '' //slider
    }
  },
  data () {
    return {
      list: new Array(14).fill('').map((item, index) => ({
        id: index, 
        img: require(`../assets/partnets/partnet-${(index+1).toString().padStart(2, '0')}.jpg`)
      }))
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import '../styles/variables.scss';

.partners{
  .partnet{
    display: flex;
    flex-direction: column;
    justify-content: center;
    &:hover{
      .img-wrap{
        box-shadow: -1px 0 14px 0 rgba(66,63,141, .1);
        transform: translateY(-4px);
      }
      .text{
        color: $primary-color;
      }
    }
    .img-wrap{
      width: 150px;
      height: 150px;
      padding: 10px;
      // border: 4px solid rgba($primary-color, .4);
      margin: 0 auto;
      border-radius: 100%;
      overflow: hidden;
      background-color: #fff;
      transition: transform .2s;
      .img{
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
      }
    }
    .text{
      margin-top: 24px;
      text-align: center;
      font-size: 18px;
      color: rgba(0,0,0, .5)
    }
  }
  &.list-container{
    display: flex;
    flex-wrap: wrap;
    .partnet-wrap{
      width: 20%;
      display: flex;
      justify-content: center;
      margin: 0 0 50px 0;
    }
  }
}
</style>