import $axios from './http'

// 基础数据模块
export const getBaseData = () => $axios.get('/basedata/getBaseData/') // 获取基础数据

// 产品类别
export const getEnabledCategroyList = (params) => $axios.get('/product/enabledCategoryList/', {params})

// 产品
export const getProductList = (params) => $axios.get('/product/', {params})

// 留言
export const createMessage= (data) => $axios.post('/message/', { data }) 