import axios from 'axios'
import qs from 'qs'
// import * as tools from '../utils'
import httpStatus from './http-status'
// import { Message } from 'element-ui'
// import router from '../router'

// axios 的实例及拦截器配置
const $axios = axios.create({
  baseURL: process.env.VUE_APP_API,
  timeout: 120000
})

// 拦截 请求
// $axios.interceptors.request.use(config => {
//   const token = tools.getStore('admin-token') || ''
//   if(!config.params){
//     config.params = {}
//   }
//   config.params.token = token
//   // config.headers.Authorization = 'Bearer ' + token
//   return config
// }, err => {
//   return Promise.reject(err)
// })

// 拦截 响应
$axios.interceptors.response.use(
  res => {
    // if (res.headers['content-disposition']) {
    //   // 下载文件
    //   tools.downloadFile(res)
    //   return Promise.resolve({})
    // }
    if (res.status === 200) {
      // success 获取验证码接口略微不同
      const { data, data: { status, success, msg }, config: { url, customParams = { errAlert: false, successAlert: false } } } = res
      // 如果url后缀是.json，那么就直接返回结果
      if (url.endsWith('.json')) {
        return Promise.resolve(data)
      }
      // 处理后端返回的错误
      if (status === 1 || success) {
        customParams.successAlert && alert(msg || 'Success')
        return Promise.resolve(data)
      }
      customParams.errAlert && alert(msg || 'Internal Server Error')
      return Promise.reject(data)
    } else {
      return Promise.reject(res.data)
    }
  },
  err => {
    const { code, message, config: { customParams = { errAlert: false, successAlert: false } } } = err
    if (code === 'ECONNABORTED' && message.indexOf('timeout') !== -1) { //  判断请求超时
      const msg = 'Request timed out, please refresh and reload',
        error = {
          status: 0,
          data: '',
          msg: msg
        }
      customParams.errAlert && alert(msg)
      return Promise.reject(error)
    }
    if (err && err.request) {
      const status = err.request.status
      err.msg = httpStatus[status] || 'Network error'
      // switch (status) {
      //   case 401:
      //     tools.removeStore('admin-token').then(() => {
      //       router.push({ name: 'login', query: { redirect: router.currentRoute.path } })
      //     })
      //     break
      //   case 422:
      //     var { errors = {} } = err.response.data
      //     var list = Object.values(errors).reduce((prev, next) => [...prev, ...next], [])
      //     err.msg = list.join(',  ')
      //     break
      //   default:
      // }
    }
    customParams.errAlert && alert(err.msg)
    return Promise.reject(err)
  }
)

$axios.get = (url, { params = {} } = {}, config = {}) => {
  // 排除空值
  // params = Object.fromEntries(Object.entries(params).filter(([k, v]) => v !== ''))

  return $axios.request(Object.assign({
    method: 'get',
    url: `${url}`,
    params: params,
    headers: {
      'Content-Type': 'application/json'
    },
    customParams: { errAlert: true, successAlert: false }
  }, config))
}

['post', 'put', 'patch', 'delete'].forEach(method => {
  $axios[method] = (url, { data = {}, params } = {}, config = {}) => {
    return $axios.request(Object.assign({
      method: method,
      url: `${url}`,
      params: params,
      data: data,
      // 对data进行格式转换
      transformRequest: [function (data) {
        // 排除空值key
        // data = Object.fromEntries(Object.entries(data).filter(([k, v]) => v !== ''))
        return qs.stringify(data)
      }],
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      customParams: { errAlert: true, successAlert: true }
    }, config))
  }
})

$axios.export = (url, { params } = {}, config = {}) => {
  return $axios.request(Object.assign({
    method: 'get',
    url: `${url}`,
    params: params,
    headers: {
      'Content-Type': 'application/json',
    },
    responseType: 'arraybuffer',
    customParams: { errAlert: true, successAlert: false }
  }, config))
}

export default $axios
