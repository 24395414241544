<template>
  <div :class="['banner-list', {multiple: multiple}]" :style="{height: windowHeight+'px'}">
    <transition-group tag="div" name="fade" >
      <div v-for="(banner, index) in bannerList" 
           v-show="index === currentIndex" 
           :key="banner.imgUrl" 
           class="banner" 
           :style="{'background-image': `url(${banner.imgUrl})`, 'background-position': `${position}`}">
           <!-- <img :src="banner.imgUrl" alt="" class="a"> -->
        <div class="content" v-if="banner.title">
          <h6 v-if="banner.title">{{banner.title}}</h6>
          <h6 v-if="banner.title2">{{banner.title2}}</h6>
          <p v-if="banner.subTitle">{{banner.subTitle}}</p>
        </div>
        <div class="mask" :style="{'background': `rgba(0,0,0,${opacity})`}"></div>
      </div>
    </transition-group>
    <div class="btn-action btn-prev" @click="onPrev"></div>
    <div class="btn-action btn-next" @click="onNext"></div>
  </div>
</template>

<script>
export default {
  name: 'Banner',
  props: {
    imgUrl: String,
    title: String,
    title2: String,
    subTitle: String,
    scale: {
      type: Number,
      default: 1
    },
    opacity: {
      type: Number,
      default: 0
    },
    position: {
      type: String,
      default: 'top'
    },
    bannerList: {
      type: Array,
      default: () => ([])
    }
  },
  computed: {
    multiple(){
      return this.bannerList.length > 1
    }
  },
  data () {
    return {
      windowHeight: window.innerHeight * this.scale,
      currentIndex: 0,
      timer: null
    }
  },
  mounted(){
    if(this.bannerList.length > 1){
      this.run()
    }
  },
  methods: {
    run(){
      this.timer = setTimeout(() => {
        if(this.currentIndex >= this.bannerList.length - 1){
          this.currentIndex = 0
        }else{
          this.currentIndex++
        }
        clearTimeout(this.timer)
        this.timer = null
        this.run()
      }, 5000)
    },
    onPrev(){
      clearTimeout(this.timer)
      this.timer = null
      if(this.currentIndex == 0){
        this.currentIndex = this.bannerList.length - 1
      }else{
        this.currentIndex--
      }
      this.run()
    },
    onNext(){
      clearTimeout(this.timer)
      this.timer = null
      if(this.currentIndex == this.bannerList.length - 1){
        this.currentIndex = 0
      }else{
        this.currentIndex++
      }
      this.run()
    }
  } 
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import '../styles/variables.scss';

.banner-list{
  position: relative;
  width: 100%;
  &.multiple:hover{
    .btn-action{
      transform: translate(0, -50%);
    }
  }
}
.banner{
  position: absolute;
  top:0;
  left: 0;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  // background-position: center;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  .mask{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
  }
  .content{
    text-align: center;
    position: relative;
    z-index: 9;
    width: 80%;
    h6{
      font-size: 48px;
      text-transform: uppercase;
      margin-bottom: 14px;
      font-weight: 400;
      line-height: 60px;
    }
    p{
      margin-top: 30px;
      font-size: 30px;
      line-height: 40px;
      color: rgba(255, 255, 255, .5);
    }
  }
}
.btn-action{
  padding: 20px 12px;
  text-align: center;
  background-color: rgba(255, 255, 255, .2);
  cursor: pointer;
  position: absolute;
  top: 50%;
  z-index: 99;
  border-radius: 4px;
  transition: transform .3s ease-out;
  &::before{
    content: '';
    display: inline-block;
    width: 20px;
    height: 20px;
    border-width: 2px;
    border-style: solid;
    transform: rotate(-45deg);
    position: relative;
    top: 2px;
  }
  &.btn-prev{
    left: 20px;
    transform: translate(-150%, -50%);
    &::before{
      border-color: #fff transparent transparent #fff;
      transform: rotate(-45deg);
      left: 6px;

    }
  }
  &.btn-next{
    right: 20px;
    transform: translate(150%, -50%);
    &::before{
      border-color: #fff #fff transparent transparent;
      transform: rotate(45deg);
      right: 6px;
    }
  }
}
</style>