<template>
  <div class="advantage-container">
    <!-- <div class="box" v-for="(item, index) in list" :key="index">
      <div class="icon-wrap">
        <span class="circle"></span>
        <span :class="[item.icon, 'icon']"></span>
      </div>
      <h5 class="title">{{item.title}}</h5>
      <div class="img" :style="{'background-image': `url(${item.imgUrl})`}"></div>
    </div> -->
      <!-- <ImageText :key="index"
                class="box"
                :icon="item.icon"
                :order="'0'+(index+1)"
                :text="item.subTitle"></ImageText> -->
                <!--   :direction="index%2===0 ? 'normal': 'reserve'" -->
    <div class="newBox" v-for="(item, index) in list" :key="index">
      <div class="img" :style="{'background-image': `url(${item.imgUrl})`}">
        <div :class="[item.icon, 'icon']"></div>
      </div>
      <div class="info">
        <h5 class="title">{{item.title}}</h5>
      </div>
    </div>            
  </div>
</template>

<script>
// import ImageText from './ImageText.vue'

export default {
  name: 'Advantage',
  components: {
    // ImageText
  },
  props: {
  },
  data () {
    return {
      list: [
        {
          icon: 'custom-icon3-automobile', 
          title: 'Automobile industry',
          imgUrl: require(`../assets/home/automobile-industry.jpg`)
        },
        {
          icon: 'custom-icon3-communication', 
          title: 'Communication network',
          imgUrl: require(`../assets/home/communication-network.jpg`)
        },
        {
          icon: 'custom-icon3-industrial', 
          title: 'Industrial Control',
          imgUrl: require(`../assets/home/industrial-control.jpg`)
        },
        {
          icon: 'custom-icon3-instrumentation', 
          title: 'Instrumentation',
          imgUrl: require(`../assets/home/instrumentation.jpg`)
        },
        {
          icon: 'custom-icon3-intelligent', 
          title: 'Intelligent Control',
          imgUrl: require(`../assets/home/intelligent-control.jpg`)
        },
        {
          icon: 'custom-icon3-power', 
          title: 'Power Systems',
          imgUrl: require(`../assets/home/power-systems.jpg`)
        }
      ],
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.advantage-container{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  padding-bottom: 30px;
  .newBox{
    width: 31%;
    margin-bottom: 40px;
    background: #fff;
    box-shadow: 0 0px 20px rgba(66 63 141 / 15%);
    border-radius: 10px;
    overflow: hidden;
    transition: transform .3s;
    &:hover{
      transform: translateY(-20px);
      .img{
        &::before{
          opacity: 1;
          transform: translateX(100%);
        }
      }
    }
    .img{
      width: 100%;
      height: 200px;
      background-image: url('https://dss2.bdstatic.com/8_V1bjqh_Q23odCf/pacific/1994006945.png');
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      position: relative;
      &::before{
        content: '';
        display: block;
        height: 100%;
        width: 100%;
        // animation: loading 8s linear infinite;
        background-image: linear-gradient(-62deg, transparent 48%, rgba(255,255,255, .2) 55%, transparent 62%);
        // background-size: 400% 100%;
        transform: translateX(-100%);
        opacity: 0;
        transition: transform 1.2s;
      }
      .icon{
        position: absolute;
        left: 50px;
        bottom: -30px;
        width: 60px;
        height: 60px;
        border-radius: 100%;
        color: #423f8d;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #ffff;
      }
    }
    .info{
      margin-top: 10px;
      padding: 32px;
      .title{
        font-size: 18px;
      }
    }
  }
}
</style>