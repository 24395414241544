<template>
  <div ref="slider" class="slider">
    <div class="slider-inner" :style="{width: (pageWidth*list.length)+'px', transform: `translateX(${-pageWidth * currentIndex}px)`}">
      <div class="slider-item" :style="{width: pageWidth+'px'}" v-for="(item, index) in list" :key="index">
        <slot :item="item"></slot>
      </div>
    </div>
    <div class="dotted-list">
      <span :class="['dotted', {active: currentIndex === index}]" @click="onChange(index)" v-for="(item, index) in dottedList" :key="index"></span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Slider',
  props: {
    size: {
      type: Number,
      default: 1
    },
    list: {
      type: Array,
      default: () => ([])
    },
    autoplay: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      totalWidth: 0,
      currentIndex: 0,
      pageWidth: 0,
      dottedList: [],
      timer: null
    }
  },
  mounted(){
    this.totalWidth = this.$refs.slider.getBoundingClientRect().width
    this.currentIndex = 0
    this.pageWidth = this.totalWidth / this.size
    this.dottedList = new Array(this.list.length - (this.size - 1 )).fill('')
    // 自动播放
    if(this.autoplay){
      this.play()
    }
  },
  methods: {
    onChange(index){
      this.currentIndex = index
      clearTimeout(this.timer)
      this.timer = null
      this.play()
    },
    play(){
      this.timer = setTimeout(() => {
        if(this.currentIndex === this.dottedList.length - 1){
          this.currentIndex = 0
        }else{
          this.currentIndex++
        }
        clearTimeout(this.timer)
        this.timer = null
        this.play()
      }, 5000)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import '../styles/variables.scss';

.slider{
  width: 100%;
  position: relative;
  overflow: hidden;
  padding: 20px 0 80px 0;
  .slider-inner{
    transition: transform .5s;
  }
  .slider-item{
    float: left;
  }
  .dotted-list{
    position: absolute;
    left: 50%;
    bottom: 10px;
    transform: translateX(-50%);
    .dotted{
      display: inline-block;
      width: 8px;
      height: 8px;
      border-radius: 8px;
      background-color: $primary-color;
      opacity: 0.3;
      margin: 0 6px;
      cursor: pointer;
      transition: all .3s;
      &.active{
        opacity: 0.8;
        width: 24px;
      }
    }
  }
}
</style>