<template>
  <div class="tabs" ref="tabsRef">
    <div class="aside">
      <!-- <h6>Our Products</h6> -->
      <div :class="['category', hasBg ? 'bg-light':'bg-white']">
        <ul>
          <li v-for="(category) in categoryList" 
              :key="category.name" 
              :class="[{active: currentCategoryId == category.id}]"
              ref="tabRef"
              @click="onChangeCategory(category.id)">
            {{category.name}}
          </li>
        </ul>
        <span class="btn-switch" :style="{width: buttonTranslate.width+'px', left: buttonTranslate.left+'px'}"></span>
      </div>
    </div>
    <div class="content">
      <div class="product-wrap" v-if="products.length">
        <div :class="['product', {'grey': border}]" v-for="product in products" :key="product.id">
          <div :class="['image-wrap']">
            <div class="image" :style="{'background-image': `url(${getImgUrl(product.imgUrl)})`}"></div>
          </div>
          <div class="text">
            <div class="title-wrap">
              <span class="title" v-if="product.name">{{product.name}}</span>
              <span class="price" v-if="product.price">${{product.price}}</span>
            </div>
            <p class="desc" v-if="product.desc">{{product.desc}}</p>
          </div>
        </div>
      </div>
      <div class="no-data" v-else>
        {{loading ? 'Loading...' : 'No Data'}}
      </div>
      <template v-if="showPage && pagination.total > 0">
        <Pagination @change="onPaginationChange" :total="pagination.total" :size="pagination.size" :page="pagination.page"/>
      </template>
    </div>
  </div>
</template>

<script>
import Pagination from './Pagination.vue'
import {getEnabledCategroyList, getProductList} from '../api/api'

export default {
  name: 'ProductTabs',
  components: {
    Pagination
  },
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    showPage: {
      type: Boolean,
      default: false
    },
    border: {
      type: Boolean,
      default: false
    },
    hasBg: {
      type: Boolean,
      default: false
    },
    value: [String, Number]
  },
  data () {
    return {
     products: [],
     currentCategoryId: '',
     offsetTop: 0,
     categoryList: [],
     pagination: {
       total: 0,
       size: 8,
       page: 1
     },
     loading: false,
     buttonTranslate: {left: 0, width: 0}
    }
  },
  watch: {
    currentCategoryId(nVal){
      this.$emit('change', nVal)
    }
  },
  async created(){
    const {data: list} = await getEnabledCategroyList()
    this.categoryList = list
    const valueIndex = this.categoryList.findIndex(item => item.id == this.value )
    this.currentCategoryId = valueIndex >= 0 ? this.value : (list[0] ? list[0].id : '')
    this.$nextTick(() => {
      this.setSwitchBtn(this.currentCategoryId)
    })
    await this.requestProductList(this.currentCategoryId, 1)
  },
  mounted(){
    setTimeout(() => {
      if(!this.$refs.tabsRef){
        return 
      }
      this.offsetTop = this.$refs.tabsRef.offsetTop - 60
    }, 300)
  },
  methods: {
    setSwitchBtn(id){
      let index = id === 0 ? 0 : this.categoryList.findIndex(item => item.id == id)
      index = Math.max(index, 0)
      if(this.$refs.tabRef){
        const {offsetLeft, offsetWidth} = this.$refs.tabRef[index]
        this.buttonTranslate = {left: offsetLeft, width: offsetWidth}
      }
    },
    getImgUrl(url){
      return url ? `${process.env.VUE_APP_API_BASE}${url}` : url
    },
    async requestProductList(category='', page=1){
        const size = this.pagination.size || 6
        this.loading = true
        const {data = {}} = await getProductList({page, size, category, status: 1}) 
        // count: 0  page: 1 results: [] size: 6
        this.products = data.results || []
        this.pagination.total = data.count
        this.loading = false
    },
    onChangeCategory(id){
      this.pagination.page = 1
      this.requestProductList(id, 1)
      this.setSwitchBtn(id)
      this.currentCategoryId = id
    },
    async onPaginationChange(index){
      this.pagination.page = index
      await this.requestProductList(this.currentCategoryId, index)
      window.scrollTo( 0, this.offsetTop );
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import '../styles/variables.scss';

.bg-white{
  background: #fff;
}
.tabs{
  // display: flex;
  .aside{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px 20px;
    margin-bottom: 40px;
    // border-right: 4px solid #eee;
    // h6{
    //   color: #423f8d;
    //   text-transform: uppercase;
    //   font-size: 32px;
    //   font-weight: 400;
    //   flex: 1;
    // }
    .category{
      border-radius: 40px;
      position: relative;
      // background-color: #fff;
      .btn-switch{
        position: absolute;
        top:0;
        left:0;
        width: 0;
        height: 100%;
        background-color: $primary-color;
        border-radius: 40px;
        transition: all .3s;
      }
      ul{
        display: flex;
        justify-content: flex-end;
        position: relative;
        z-index: 3;
      }
      li{
        min-width: 140px;
        padding: 15px 20px;
        text-align: center;
        font-size: 18px;
        font-weight: bold;
        position: relative;
        cursor: pointer;
        // color: rgba(0,0,0,.4);
        &.active{
          color: #fff
        }
        // transition:  color .3s;
        // &::after{
        //   content: '';
        //   position: absolute;
        //   top:0;
        //   bottom: 0;
        //   left:0;
        //   width: 100%;
        //   height: 3px;
        //   border-radius: 4px;
        //   background-color: transparent;
        //   transition: background .3s;
        // }
        // &.active, &:hover{
        //   color: $primary-color;
        // }
        // &.active{
        //  &::after{
        //   background-color: $primary-color;
        // }
        // }
      }
    }
  }
  .content{
    flex: 1
  }
  .no-data{
    text-align: center;
    font-size: 24px;
    padding: 60px;
    color: rgba(0,0,0,.5);
  }
  .product-wrap{
    padding: 20px 0;
    display: flex;
    flex-wrap: wrap;
    // justify-content: space-evenly;
    &::after {
      content: '';
      min-width: 40px; // 这样就不会硬挤出点空间给它,不够min-width就会下一行
      height: 0; // 这样即使没有多余空间,挤下另一行,也不会影响,因为height:0
      flex: 1; //默认占满剩余空间
    }
    .product{
      width: 280px;
      padding: 15px;
      cursor: pointer;
      background-color: #fff;
      margin: 0 20px 20px 0;
      // box-shadow: 0 10px 20px rgb(0 0 0 / 5%);
      border-radius: 10px;
      // border: 2px solid transparent;
      transition: all .3s;
      &.grey{
        border: 1px solid #eee;
      }
      &:hover{
        box-shadow: 0 10px 20px rgb(66 63 141 / 15%);
        transform: translateY(-8px);
        // border-color: rgba(66,63,141, .6);
        // box-shadow: 0px 5px 39px rgba(154, 87, 87, 0.1);
        // .image{
        //   // transform: scale(1.1);
        // }
      }
      .image-wrap{
        // width: 80%;
        height: 250px;
        border-radius: 6px;
        margin: 0 auto 24px auto;
        overflow: hidden;
        &.border{
          border: 1px solid #eee;
          padding: 10px;
        }
      }
      .image{
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        transition: transform .3s;
        background-color: #fff;
      }
      .text{
        margin: 10px 0 0 0;
        padding: 0 10px;
        .title-wrap{
          display: flex;
          .title{
            flex: 1;
            font-size: 20px;
          }
          .price{
            color: $primary-color;
            font-size: 20px;
            font-weight: bold;
            margin-left: 8px;
          }
        }
        .desc{
          font-size: 18px;
          margin-top: 8px;
          color: rgba(0,0,0,.5);
        }
      }
    }
  }
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 2s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}
</style>