<template>
  <div class="title">
    <h2>{{title}}</h2>
    <!-- <p><img src="../assets/split.png" alt=""></p> -->
  </div>
</template>

<script>
export default {
  name: 'Title',
  props: {
    title: String
  },
  data () {
    return {
      
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.title{
  text-align: center;
  padding: 20px 0 50px 0;
  h2{
    color: #423f8d;
    letter-spacing: 4px;
    text-transform: uppercase;
    margin-bottom: 16px;
    font-size: 36px;
    font-weight: 400;
  }
}
</style>