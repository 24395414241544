<template>
  <div :class="['footer', isAtHome ? 'home' : 'normal']">
    <div class="contact-form" v-if="isAtHome">
      <div class="border-top"></div>
      <div class="border-left"></div>
      <div class="form-wrap">
        <div class="left">
          <h6>Contact Us</h6>
          <p>Should you have any enquiries about electronic components, please fill in our enquiry form</p>
        </div>
        <div class="form mini">
          <div class="form-item">
            <div style="margin-right: 20px;">
              <input type="text" v-model="form.title"  class="input" placeholder="Title" />
            </div>
            <div style="">
              <input type="text" v-model="form.contact" class="input" placeholder="Your Email / Phone" />
            </div>
          </div>
          <div class="form-item">
            <textarea v-model="form.content" class="input textarea" placeholder="Your Message" />
          </div>
          <div class="error" v-if="showError">
            Please fill in the all fields
          </div>
          <div class="form-footer">
            <div class="btn-submit" @click="onCreate">{{sending ? 'Sending...' : 'Send a Request'}}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="main">
      <div class="main-content">
        <div class="logo">
          {{baseInfo.company_name}}
        </div>
        <div class="content">
          <div class="text" v-for="(arr, index) in length" :key="index">
            <template v-for="item in arr">
              <p v-if="item.type != 'qq'" :key="item.type">
                <span class="icon">
                  <i :class="item.img"></i>
                </span>
                <span>{{item.text}}</span>
              </p>
              <a v-else target="_blank" :href="`http://wpa.qq.com/msgrd?v=3&uin=${item.text}&site=qq&menu=yes`" :key="item.type">
                <span class="icon">
                 <i :class="item.img"></i>
                </span>
                <span>{{item.text}}</span>
              </a>
            </template>
          </div>
        </div>
      </div>
      <div class="owner">
        <p class="copyright">Copyright ©2021 Shenzhen Yiyouda Technology Co., Ltd. All rights reserved <a class="gov" target="_blank" href="https://beian.miit.gov.cn/" style="margin-left: 20px; font-size: 13px;">粤ICP备20069290号</a></p>
      </div>
    </div>
  </div>
</template>

<script>
// import { createMessage } from '../api/api'

export default {
  name: 'Footer',
  // inject: ['getBaseInfo'],
  props: {
    baseInfo: {
      type: Object,
      default: () => ({})
    }
  },
  computed:{
    infoList(){
      return  [
        {type: 'phone', img: 'custom-icon2-phone', text: this.baseInfo.phone},
        {type: 'email', img: 'custom-icon2-email', text: this.baseInfo.email},
        {type: 'qq', img: 'custom-icon2-qq', text: this.baseInfo.qq},
        {type: 'skype', img: 'custom-icon2-skype', text: this.baseInfo.contact},
        {type: 'wechat', img: 'custom-icon2-wechat', text: this.baseInfo.wechat},
        {type: 'address', img: 'custom-icon2-address', text: this.baseInfo.address}
      ].filter(item => item.text)
    },
    length(){
      var R = [],F;
      for (F = 0;F < this.infoList.length;) {
        R.push(this.infoList.slice(F,F += 2))
      }
      return R
    }
  },
  watch: {
    $route: {
      handler: function(){
        this.isAtHome = this.$route.name === 'home'
          
      },
      immediate: true
    }
  },
  data () {
    return {
      form: {
        title: '',
        contact: '',
        content: ''
      },
      showError: false,
      sending: false,
      isAtHome: false
      // baseInfo: this.getBaseInfo()
    }
  },
  methods: {
    async onCreate(){
      try{
        const isValid = this.form.title && this.form.contact && this.form.content
        if(this.sending){
          return 
        }
        if(!isValid){
          this.showError = true
          return 
        }
        this.showError = false
        this.sending = true
        // await createMessage(this.form)
        this.form = {
          title: '',
          contact: '',
          content: ''
        }
      }finally{
        this.sending = false
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.footer{
  position: relative;
  overflow: hidden;
  &.normal{
    padding: 60px 0 0 0;
    background-image: linear-gradient(to right, rgba(141,20,220) , rgba(37,6,128));
    .contact-form{
      display: none;
    }
  }
  &.home{
    padding: 660px 0 0 0;
    // background: #423f8d;
    &::before{
      content: '';
      position: absolute;
      left: 0px;
      top: 380px;
      width: 102%;
      height: 200%;
      // border-radius: 0 60px 0 0;
      background-image: linear-gradient(to right, rgba(141,20,220) , rgba(37,6,128));
      // transform: rotate(-5deg);
    }
    .contact-form{
      position: absolute;
      top: 87px;
      left:50%;
      transform: translateX(-50%);
      // transform: translateX(-50%) rotate(-3deg);
      width: 70%;
      // height: 380px;
      background-color: #fff;
      border-radius: 20px;
      box-shadow: 0 0px 30px rgba(141, 20,220, .05);
      // box-shadow: 5px 15px 20px -5px rgba(141, 20,220, .1);
      // box-shadow: 0 0 20px rgba(141, 20,220, .3);
      // .border-top{
      //   position: absolute;
      //   right: 0px;
      //   top: -29px;
      //   width: 99%;
      //   height: 65px;
      //   border-radius: 0 20px 0 0;
      //   background: #fff;
      //   transform: rotate(5deg);
      // }
      // &::before{
      //   content: '';
      //   position: absolute;
      //   right: 0px;
      //   top: -20px;
      //   width: 100%;
      //   transform: rotate(5deg);
      //   width: 100%;
      //   height: 2px;
      //   box-shadow: 0 -6px 20px rgba(141, 20,220, .5);
      // }
      // .border-left{
      //   position: absolute;
      //   left: -26px;
      //   top: -79px;
      //   bottom: 1px;
      //   width: 59px;
      //   border-radius: 20px 0 5px 20px;
      //   background: #fff;
      //   transform: rotate(5deg);
      //   z-index: 1;
      // }
      // &::after{
      //   content: '';
      //   position: absolute;
      //   left: -20px;
      //   top: -75px;
      //   bottom: 2px;
      //   width: 2px;
      //   transform: rotate(5deg);
      //   box-shadow: -6px 0 20px rgba(141, 20,220, .5);
      // }
      .form-wrap{
        // transform: rotate(3deg);
        // padding: 10px 60px 10px 20px;
        padding: 40px;
        position: relative;
        z-index: 6;
        .left{
          // width: 30%;
          h6{
            font-size: 30px;
            margin-bottom: 15px;
            color: #423f8d;
          }
          p{
            font-size: 15px;
            color: #808080;
          }
        }
        .form{
        margin-top: 50px;
        }
      }
    }
  }
  .main-content{
    position: relative;
    padding-bottom: 10px;
    display: flex;
    border-bottom: 1px solid rgba(255, 255, 255 ,0.2);
    z-index: 2;
  }
  .logo{
    color: rgba(255, 255, 255 ,0.9);
    font-size: 36px;
  }
  .content{
    flex: 1;
    display: flex;
    justify-content: space-evenly;
    color: #fff;
    font-family: Arial, Helvetica, sans-serif;
    .icon{
      width: 20px;
      margin-right: 10px;
      display: flex;
      align-items: center;
      i{
        font-size: 20px;
        &.custom-icon2-qq{
          font-size: 18px;
        }
        &.custom-icon2-address{
          font-size: 19px;
        }
      }
    }
    .text{
      &>p,&>a{
        display: flex;
        align-items: center;
        padding: 0 20px;
        margin-bottom: 20px;
        opacity: 0.9;
        line-height: 22px;
      }
    }
  }
  .owner{
    font-family: Arial, Helvetica, sans-serif;
    position: relative;
    z-index: 9;
    padding: 30px 0;
    color: rgba(255, 255, 255 ,0.8);
    .copyright{
      text-align: center;
    }
  }
  .gov{
    &:hover{
      text-decoration: underline;
    }
  }
}
</style>