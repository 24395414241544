export default {
  400: 'Request error',
  401: 'Unauthorized, please login',
  403: 'Access denied',
  404: 'Error requesting address',
  408: 'Request timeout',
  500: 'Server internal error',
  501: 'Server not implemented',
  502: 'Gateway error',
  503: 'Service unavailable',
  504: 'Gateway timeout',
  505: 'HTTP version not supported'
}
