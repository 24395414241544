import VueRouter from 'vue-router'
import Home from '../pages/Home'

// const scrollBehavior = (to, from, savedPosition) => {
//   if (savedPosition) {
//   // savedPosition is only available for popstate navigations.
//   return savedPosition
//   } else {
//   const position = {}
//   // new navigation.
//   // scroll to anchor by returning the selector
//   if (to.hash) {
//   position.selector = to.hash
//   }
//   // check if any matched route config has meta that requires scrolling to top
//   if (to.matched.some(m => m.meta.scrollToTop)) {
//  // cords will be used if no selector is provided,
//  // or if the selector didn't match any element.
//   position.x = 0
//   position.y = 0
//  }
//   // if the returned position is falsy or an empty object,
//   // will retain current scroll position.
//   return position
//   }
// }

const routes = [
    { path: '/', name: 'home', component: Home },
    {
        path: '/about', 
        name: 'about',
        component: () => import('../pages/About.vue')
    },
    {
        path: '/products', 
        name: 'products',
        component: () => import('../pages/Products.vue')
    },
    // {
    //     path: '/services', 
    //     name: 'services',
    //     component: () => import('../pages/Services.vue')
    // },
    {
        path: '/contact', 
        name: 'contact',
        component: () => import('../pages/Contact.vue')
    }
]

const router = new VueRouter({
    routes // (缩写) 相当于 routes: routes
})

// 每次切换页面都滚回顶
router.afterEach((route) => {
  if(!route.hash){
    window.scrollTo(0,0);
  }
});

export default router