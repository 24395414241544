<template>
  <div class="home-page page-font">
    <!-- Banner -->
    <Banner :bannerList="bannerList"/>

      <!-- 我们的优势 -->
      <div class="about-advantage main" style="padding: 30px 0 0 0">
        <Title title="OUR APPLICATIONS"/>
        <VisibleBox>
          <Advantage></Advantage>
        </VisibleBox>
      </div>

    <!-- 公司介绍 -->
    <div class="bg-light" style="padding-bottom: 30px">
      <AboutCompany />
      <router-link :to="{ name: 'about' }">
        <div class="view-more-wrap">
          <div class="view-more">
            View More
          </div>
        </div>
      </router-link>
      <!-- <div class="about-company main" style="padding: 20px 0">
        <Title title="About Us"/>
        <VisibleBox>
          <AboutCompany />
          <router-link :to="{ name: 'about' }">
            <div class="view-more-wrap">
              <div class="view-more">
                View More
              </div>
            </div>
          </router-link>
        </VisibleBox>
      </div> -->
    </div>

    <!-- 合作伙伴 -->
    <div class="about-partner main" style="padding: 20px 0">
      <Title title="OUR STRONG BRAND"/>
      <VisibleBox>
        <Partners type="slider" />
        <router-link to="/products#partner">
          <div class="view-more-wrap">
            <div class="view-more">
              View More
            </div>
          </div>
        </router-link>
      </VisibleBox>
    </div>

    <!-- 产品 -->
    <div class="bg-light">
      <div class="about-products main" style="padding: 40px 0;">
        <Title title="our products"/>
        <VisibleBox>
          <ProductTabs v-model="category"/>
          <router-link :to="{ name: 'products', query: {category}}">
            <div class="view-more-wrap">
              <div class="view-more">
                View More
              </div>
            </div>
          </router-link>
        </VisibleBox>
      </div>
    </div>

    <!-- 联系 -->
    <!-- <div class="contact main" style="padding: 60px 0;">
      <Title title="Contact Us"/>
      <VisibleBox>
        <ContactUs/>
      </VisibleBox>
    </div> -->
  
  </div>
</template>

<script>
import Banner from '../components/Banner.vue'
import Title from '../components/Title.vue'
import ProductTabs from '../components/ProductTabs.vue'
// import ContactUs from '../components/ContactUs.vue'
import VisibleBox from '../components/VisibleBox.vue'
import Advantage from '../components/Advantage.vue'
import AboutCompany from '../components/AboutCompany.vue'
import Partners from '../components/Partners.vue'

export default {
  name: 'HomePage',
  components: {
    Banner,
    Title,
    ProductTabs,
    // ContactUs,
    VisibleBox,
    Advantage,
    AboutCompany,
    Partners
  },
  props: {
    msg: String
  },
  data(){
    return {
      bannerList: [
        {
          imgUrl: require(`../assets/home/home-banner-01.jpg`), 
          title: `Welcome to`, 
          title2: `Shenzhen Yiyouda Technology Co., Ltd.`
        },
        // {
        //   imgUrl:  require(`../assets/home/home-banner-02.jpg`), 
        //   title: `100% New & Original`, 
        //   subTitle: `Spot inventoty | Fast delivery | Quality assurance | Reasonable price | Service first`
        // }
      ],
      category: ''
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import '../styles/variables.scss';

</style>
